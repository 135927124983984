.main{
    width: 100%;
    min-height: 50rem;
    background: url("../staticImages/game_image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.navbar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #130c1cb1;
    gap: 6rem;
    max-height: 7rem;
}

.logo_container{
    padding: 2rem;
}
.logo{
    width: 6rem;
    height: 4rem;
    object-fit: contain;
}

.categories{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 7rem;
    /* gap: 2rem; */
}

.link.active{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 15rem;
    background: #FFE900;
    padding: 2rem;
    transform:skewX(-20deg);
    cursor: pointer;
}

.link.active .link_text{
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}

.link{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 15rem;
    padding: 2rem;
    transform:skewX(-20deg);
    cursor: pointer;
    transform: all 0.3s;
}

.link_text{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}

.link:hover{
    background: #FFE900;
}

.link:hover .link_text{
    color: black;
}

.main_container{
    position: absolute;
    width: 100%;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-60%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
    padding: 0 10rem 0 10rem;
}

.text_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.main_heading{
    font-size: 8rem;
    color: white;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 0.6rem;
}

.main_heading span{
    color: #FFE900;
}

.main_description{
    font-size: 1.8rem;
    color: white;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    letter-spacing: 0.1rem;
    width: 70%;
    text-align: center;
}

.game_btn{
    padding: 1.5rem 3rem 1.5rem 3rem;
    border: none;
    outline: none;
    color: white;
    font-size: 1.5rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 800;
    letter-spacing: 0.2rem;
    border-radius: 0.2rem;
    background: linear-gradient(to bottom, #ffea00e6, #ffea00e5), url("../staticImages/game_image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 1rem;
    box-sizing: 0 1rem 1rem rgba(0,0,0,0.4);
}

.games_section{
    background:linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../staticImages/background_dark_image.jpg");
    width: 100%;
    min-height: 30rem;
    padding: 2rem 2rem 2rem 2rem;
}

.scroll_section{
    width: 100%;
}
