.drawer{
    width: 25rem;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom,#220101,#240404);
    z-index: 300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    padding-top: 5rem;
    display: none;
}

.drawer.active{
    display: block;
    animation: show 0.3s ease-in-out;
}

.logo_container{
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 6rem;
    height: 4rem;
    object-fit: contain;
}

.categories{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.link.active{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 17rem;
    background: #FFE900;
    padding: 2rem;
    transform:skewX(-20deg);
    cursor: pointer;
}

.link.active .link_text{
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}

.link{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 17rem;
    padding: 2rem;
    transform:skewX(-20deg);
    cursor: pointer;
    transform: all 0.3s;
}

.link_text{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}

.link:hover{
    background: #FFE900;
}

.link:hover .link_text{
    color: black;
}

@keyframes show{
    0%{
        transform: translateX(-400px);
    }
    100%{
        transform: translateX(0);
    }
}