*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}
html{
    font-size: 62.5%;
    scroll-behavior: smooth;
}

/* body{
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,#220101,#240404);
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.swiper-container{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom,#220101,#240404);
  z-index: 2;
}