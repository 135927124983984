.navbar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #130c1cb1;
    background: linear-gradient(to bottom,#220101,#240404);
    background: linear-gradient(to bottom,#2201019d,#240404a5);
    gap: 6rem;
    max-height: 7rem;
    z-index: 199;
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.2);
}

.logo_container{
    padding: 2rem;
}
.logo{
    width: 6rem;
    height: 4rem;
    object-fit: contain;
}

.categories{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 7rem;
    /* gap: 2rem; */
}

.link.active{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 17rem;
    background: #FFE900;
    padding: 2rem;
    transform:skewX(-20deg);
    cursor: pointer;
}

.link.active .link_text{
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}

.link{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 17rem;
    padding: 2rem;
    transform:skewX(-20deg);
    cursor: pointer;
    transform: all 0.3s;
}

.link_text{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}

.link:hover{
    background: #FFE900;
}

.link:hover .link_text{
    color: black;
}

.container{
    width: 100%;
    z-index: 199;
    height: 50rem;
    position: relative;
    overflow: hidden;
}

.container video{
    z-index: 299;
    width: 100dvw !important;
    height: 50rem !important;
    object-fit: fill !important;
    margin-top: -10rem;
    opacity: 0.1;
}

.text_box{
    position: absolute;
    top: 0%;
    left: 0%;
    /* background: linear-gradient(to bottom,#220101,#240404); */
    height: 100%;
    width: 100%;
    color: #fff;
    display:flex;
    flex-direction: column;
    /* gap: 1rem; */
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 900;
    font-family: 'Poppins',sans-serif;
    /* mix-blend-mode:multiply; */
    mix-blend-mode:color-burn;
    text-align: center;
    margin-top: -5rem;
}

.text_color{
    color: #FFE900;
}

.text_small{
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    margin-top: 1rem;
    width: 60%;
}

.play_btn{
    margin-top: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    background: #FFE900;
    outline: none;
    border: none;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 0.4px;
    border-radius: 0.2rem;
    box-shadow: 0 1rem 1.3rem rgba(0,0,0,0.2);
    cursor: pointer;
    transition: all 0.3s;
}

.play_btn:hover{
    background: #fff15a;
}

.play_btn:active{
    transform: scale(1.1);
}



/* menu icon styles */

.menu_icon{
    display: none;
}

label{
    display:flex;
     flex-direction:column;
     width:50px;
     cursor:pointer;
   }
   
   label span{
     background: #fff;
     border-radius:10px;
     height:4px;
     margin: 4px 0;
     transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
   }
   
   
   span:nth-of-type(1){
     width:50%;
     
   }
   
   span:nth-of-type(2){
     width:100%;
   }
   
   
   span:nth-of-type(3){
     width:75%;
    
   }
   
   
   input[type="checkbox"]{
     display:none;
   }
   
   
   input[type="checkbox"]:checked ~ span:nth-of-type(1){
     transform-origin:bottom;
     transform:rotatez(45deg) translate(4px,0px)
   }
   
   
   input[type="checkbox"]:checked ~ span:nth-of-type(2){
     transform-origin:top;
     transform:rotatez(-45deg)
   }
   
   
   input[type="checkbox"]:checked ~ span:nth-of-type(3){
     transform-origin:bottom;
     width:50%;
     transform: translate(20px,-4px) rotatez(45deg);
   
   }


@media screen and (max-width:800px){
    .navbar{
        justify-content: space-between;
        padding: 0 2rem 0 2rem;
    }
    .categories{
        display: none;
    }
    .menu_icon{
        padding: 1rem 0 1rem 0;
        display: block;
    }
    .text_box{
        font-size: 1.5rem;
        text-align: center;
        padding: 0 4rem 0 4rem;
    }
    .text_small{
        width: 100%;
        font-size: 1.5rem;
    }
}


@media screen and (max-width:600px){
    .text_box{
        font-size: 1.5rem;
        text-align: center;
        padding: 0 4rem 0 4rem;
    }
}

.games_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
}


/* SECTION POPULAR GAMES */

.section_popular_games{
    width: 100%;
    z-index: 199;
    margin-top: -9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 2rem 1rem 2rem;
}

.section_action_games{
    width: 100%;
    z-index: 199;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 2rem 1rem 2rem;
}

.section_puzzle_games{
    width: 100%;
    z-index: 199;
    display: flex;
    margin-top: -9rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 2rem 1rem 2rem;
}

.top_container{
    width: 100%;
    z-index: 199;
    height: 80rem;
    position: relative;
    overflow: hidden;
}

.top_container video{
    z-index: 299;
    width: 100dvw !important;
    height: 80rem !important;
    object-fit: fill !important;
    margin-top: -10rem;
    opacity: 0.1;
}

.text_box_2{
    position: absolute;
    top: 0%;
    left: 0%;
    /* background: linear-gradient(to bottom,#220101,#240404); */
    height: 100%;
    width: 100%;
    color: #fff;
    display:flex;
    flex-direction: column;
    /* gap: 1rem; */
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 900;
    font-family: 'Poppins',sans-serif;
    /* mix-blend-mode:multiply; */
    mix-blend-mode:color-burn;
    text-align: center;
    margin-top: -5rem;
}

.items_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 2rem 20rem 2rem 20rem; */
}

.items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 40rem;
    gap: 2rem;
    z-index: 299;
    margin-top: 2rem;
    /* flex-wrap: wrap; */
}

.top_games_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.number_image_container{
    width: 6rem;
    height: 4rem;
}

.number_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.top_games{
    cursor: pointer;
    width:25rem;
    position: relative;
}

.image{
    width: 100%;
    height: 15rem;
}

.image img{
    width: 100%;
    height: 15rem;
    object-fit: cover;
}

.text{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    color: white;
    font-size: 1.5rem;
    font-family: 'Raleway',sans-serif;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    background: linear-gradient(to right,rgba(0,0,0,0.7),rgba(0,0,0,0.8));
}

@media screen and (max-width:800px){
    .top_games{
        width:20rem;
    }
}

.center_loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0 2rem 0;
    margin-top: -10rem;
}

.loader {
    width: 48px !important;
    height: 48px !important;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 