.carousel_title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.carousel_title_heading{
    font-size: 3rem;
    color: white;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
}
.carousel_title_small_heading{
    color: #FFE900;
}

.game_carousel_container{
    width: 100%;
    overflow: hidden;
}
.game_carousel_sub_container{
    width: 100%;
}

.carousel_container{
    cursor: pointer;
    width:100%;
    position: relative;
}

.game_image{
    width: 100%;
}

.game_image_img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}

.text{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    color: white;
    font-size: 1.5rem;
    font-family: 'Raleway',sans-serif;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    background: linear-gradient(to right,rgba(0,0,0,0.7),rgba(0,0,0,0.8));
}
