.container{
    /* background: linear-gradient(to bottom,#EF4242,#240404); */
    /* background: linear-gradient(to bottom,#b22222,#240404); */
    background: linear-gradient(to bottom,#220101,#240404);
    width: 100%;
    min-height: 100dvh;
    position: relative;
    display: flex;
    justify-content: flex-start;
    /* justify-content: center; */
    align-items: flex-start;
    flex-direction: column;
    z-index: 9;
}

.rocket_img{
    width: 8rem;
    height: 4rem;
    object-fit: contain;
}

.left_side_image{
    position: absolute;
    top: 20%;
    left: 0%;
    transform: translateY(-20%);
    width: 30rem;
    max-height:100dvh ;
    overflow: hidden;
    z-index: 2;
}
.left_side_image .left_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.right_side_image{
    position: absolute;
    top: 20%;
    right: 0%;
    transform: translateY(-20%);
    width: 30rem;
    max-height:100dvh ;
    overflow: hidden;
    z-index: 2;
}
.right_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.top_side_image{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30rem;
    height: 20rem;
    filter: blur(4px);
    z-index: 2;
}
.top_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}