.container{
    width: 100%;
    z-index: 199;
    height: 100dvh;
    position: relative;
    overflow: hidden;
}

.container video{
    z-index: 299;
    width: 100dvw !important;
    height: 120dvh !important;
    object-fit: fill !important;
    margin-top: -10rem;
}

.text_box{
    position: absolute;
    top: 0%;
    left: 0%;
    background: linear-gradient(to bottom,#220101,#240404);
    height: 100%;
    width: 100%;
    color: #fff;
    display:flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 900;
    font-family: 'Poppins',sans-serif;
    /* mix-blend-mode:color-dodge; */
    mix-blend-mode:color-burn;
    text-align: center;
}

@media screen and (max-width:600px){
    .text_box{
        font-size: 1.5rem;
        text-align: center;
        padding: 0 4rem 0 4rem;
    }
}