.sub_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 8rem;
  width: 100%;
  z-index: 4;
  padding: 1rem 0 1rem 0;
}

.title_container{
    width: 100%;
    padding: 3rem 0 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.title{
    width: 80%;
    font-size: 4rem;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 0.1rem;
    font-weight: 800;
    color: #fff;
}
.text_container{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text{
    font-size: 2rem;
    color: white;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.grid{
    display: grid;
    grid-template-columns: repeat(3,10rem);
    width: 80%;
    gap: 1rem;
    align-items: center;
    align-content: center;
}

.image{
    width: 10rem;
    height: auto;
}

.image img{
    width: 100%;
    height: auto;
    object-fit: contain;
}

.form_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form{
    width: 90%;
    height: 36rem;
    background: linear-gradient(to right,#f90a0a78,#a509096a);
    /* background: linear-gradient(to bottom,#b80101a0,#980c0cb0); */
    /* backdrop-filter: blur(500px); */
    border-radius: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 3rem 2rem 3rem;
}

.form_title{
    text-align: center;
    width: 100%;
    font-size: 3.5rem;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 0.1rem;
    font-weight: 800;
    color: #fff;
}

.form_container{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.input_group{
    position: relative;
    width: 60%;
    height: 5rem;
}
.input_group .input{
    width: 100%;
    height: 5rem;
    border: none;
    outline: none;
    background: transparent;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    /* border-bottom: 0.2rem solid #350707; */
    border-bottom: 0.2rem solid #ffffff88;
    color: white;
}

.icon{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    /* color: #cd4545; */
    color: #fff;
    font-size: 2rem;
}

.input::placeholder{
    /* color: #cd4545; */
    color: #ffffffd8;
    /* color: #b22222; */
    /* -webkit-text-stroke: #b22222; */
    -webkit-text-stroke-width: 0.1rem;
    -webkit-text-fill-color: #b22222;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
}

.input:focus{
    border-bottom: 0.2rem solid #ffffff;
}

.btn{
    width: 15rem;
    /* background-color: #480202; */
    background-color: #ff0000;
    color: white;
    padding: 0.6rem 2rem 0.6rem 2rem;
    border: none;
    outline: none;
    border-radius: 0.3rem;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    cursor: pointer;
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.3);
}

@media screen and (max-width:1000px){
    .sub_container{
        grid-template-columns: 1fr;
        margin-top: 3rem;
      }
}

@media screen and (max-width:800px) {
    .form_container{
        margin-top: 0rem;
    }
    .title{
        width: 90%;
        font-size: 2.5rem;
    }
    .text_container{
        width: 90%;
    }
    .form_title{
        font-size: 2.5rem;
    }
    .input_group{
        position: relative;
        width: 90%;
        height: 5rem;
    }
    
}

.center_loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0 2rem 0;
}

.loader {
    width: 48px !important;
    height: 48px !important;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 