.container{
    width: 100%;
    min-height:100dvh;
    background-color: #1b0905;
}
.background_main{
    background:url("../staticImages/bg_main.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    width: 100%;
    height: 45rem;
    position: relative;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 2rem 2rem;
}
.logo_container h3{
    font-size: 2rem;
    color: white;
    font-family: 'Raleway',sans-serif;
    letter-spacing: 0.2rem;
}

.menu_icon{
    font-size: 2rem;
    color: white;
}

.play_btn_container{
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.play_btn{
    padding: 1rem 3rem 1rem 3rem;
    background: linear-gradient(to right,#EFD764,#EFD764);
    color: rgb(0, 0, 0);
    font-family: 'Raleway',sans-serif;
    font-size: 3rem;
    border-radius: 0.2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.7);
}

.sub_container{
    background-color: #1b0905;
    padding: 3rem 0 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 2rem;
}

.title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}
.title h3{
    font-size: 5rem;
    color: #DCBC60;
    font-family: 'Raleway',sans-serif;
    font-weight: bold;
    letter-spacing: 0.3rem;
}
.title .underline{
    width: 20rem;
    height: 0.1rem;
    background-color: #DCBC60;
}

.games_container{
    width: 100%;
    padding: 0 3rem 0 3rem;
}

.grid_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}
.grid_item{
    border: 1rem solid #C08E41;
    border-radius: 0.6rem;
    width: 100%;
    height: 30rem;
    position: relative;
    cursor: pointer;
}

.image{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.game_name_container{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.game_name_sub_container{
    background: white;
    padding: 1rem 4rem 1rem 4rem;
    border: 0.5rem solid #C08E41;
    box-shadow: 0 0.8rem 3rem rgba(0,0,0,0.7);
}

.game_name{
    color: #333;
    font-size: 1.5rem;
    font-family: 'Raleway',sans-serif;
}

.game_carousel_container{
    width: 100%;
    overflow: hidden;
}
.game_carousel_sub_container{
    width: 100%;
    padding: 1rem 5rem 1rem 5rem;
}

.carousel_container{
    cursor: pointer;
    width:100%;
    position: relative;
}

.game_image_img{
    width: 100%;
    height: 17rem;
    overflow: hidden;
}
.game_image_img img{
    width: 100%;
    height: 100%;
    object-fit:contain;
}

.text{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    color: white;
    font-size: 1.5rem;
    font-family: 'Raleway',sans-serif;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    background: linear-gradient(to right,rgba(0,0,0,0.7),rgba(0,0,0,0.8));
}

.footer_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.footer_container h3{
    color: #c1c1c1;
    font: 1.5rem;
    font-family: 'Raleway',sans-serif;
}

.center_loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0 2rem 0;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    @media screen and (max-width:800px){
        .background_main{
            height: 30rem;
            background-position: -20px 0;
        }
        .title h3{
            font-size: 3rem;
            color: #DCBC60;
            font-family: 'Raleway',sans-serif;
            font-weight: bold;
            letter-spacing: 0.3rem;
        }
        .grid_container{
            grid-template-columns: 1fr 1fr;
        }
        .games_container{
            width: 100%;
            padding: 0 1rem 0 1rem;
        }
        .grid_item{
            border: 1rem solid #C08E41;
            height: 25rem;
        }
        .game_name_sub_container{
            padding: 1rem 2rem 1rem 2rem;
        }
        .game_carousel_sub_container{
            width: 100%;
            padding: 1rem 1rem 1rem 1rem;
        }
        .game_image_img{
            width: 100%;
            height: 13rem;
            overflow: hidden;
        }
    }

    @media screen and (max-width:500px){
        .background_main{
            height: 25rem;
            background-position: -50px 0;
        }
        .games_container{
            width: 100%;
            padding: 0 1rem 0 1rem;
        }
        .grid_item{
            border: 0.8rem solid #C08E41;
            height: 18rem;
            border-radius: 0.3rem;
        }
        .game_name_sub_container{
            padding: 1rem 2rem 1rem 2rem;
        }
        .game_name{
            font-size: 1.2rem;
        }
        .text{
            font-size: 1rem;
            padding: 0.5rem 1rem 0.5rem 1rem;
        }
    }

    @media screen and (max-width:400px) {
        .games_container{
            width: 100%;
            padding: 0 1rem 0 1rem;
        }
        .grid_item{
            border: 0.8rem solid #C08E41;
            height: 18rem;
            border-radius: 0.3rem;
        }
        .background_main{
            height: 30rem;
            background-position: -130px 0;
        }
    }